/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import { nanoid } from 'nanoid';
// import { Link } from 'gatsby';
import NewFaq from '../components/NewFaq/NewFaq';

import 'styles/chrome-extension.scss';
import Header from '../components/Header/Header';
import TestimonialsSlider from '../components/TestimonialsSlider/TestimonialsSlider';
const faqData = [
  {
    q: 'How to create a web app project?',
    a: (
      <>
        Please open your web app in logged-in state OR your password-gated
        website after entering the credentials. Once loaded, please download our
        extension and click to login with your Ruttl account. Once logged-in,
        please switch over to the tab with your web app or website and click on
        our extension. At the bottom of the extension, you’ll see three options
        - Website, Web App and Bug Tracking - please click on Web App, click on
        “+New project”, give it a name and your project will be created inside
        Ruttl.
        {/* <Link to="/integrations/">&nbsp;integrations&nbsp;</Link>
        page. */}
      </>
    ),
  },
  {
    q: 'How to create a bug tracking project?',
    a: (
      <>
        Please open your web app in logged-in state OR your password-gated
        website after entering the credentials. Once loaded, please download our
        extension and click to login with your Ruttl account. Once logged-in,
        please switch over to the tab with your web app or website and click on
        our extension. At the bottom of the extension, you’ll see three options
        - Website, Web App and Bug Tracking - please click on Bug Tracking,
        click on “+New project”, give it a name and your project will be created
        inside Ruttl.
      </>
    ),
  },
  {
    q: 'When is it ideal to use Ruttl’s Chrome extension?',
    a: (
      <>
        To review web apps with login, or password-gated websites, it is best
        recommended to use our extension & review your URL on our web app OR bug
        tracking project respectively. Sometimes, despite our best efforts,
        websites may either load in a broken format or not load at all on Ruttl.
        This could happen for various reasons, such as:
        <ul>
          <li>
            Security settings like login prompts, CAPTCHA, or anti-bot measures
          </li>
          <li>Firewall or access restrictions</li>
          <li>Incompatible or broken CSS, scripts, or interactive elements</li>
          <li>Changes made to the website after it was added to ruttl</li>
          <li>Network or server-level restrictions, or</li>
          <li>Other unidentified technical conflicts</li>
        </ul>
        <p>
          Given the diversity of websites and technologies, it’s not feasible to
          accommodate every site. If your site doesn’t load on Ruttl, please
          reach out to&nbsp;
          <a
            href="mailto:support@ruttl.com"
            className="text-blue-600 hover:underline">
            support@ruttl.com
          </a>
          &nbsp;and we’ll note your website and may implement a fix for better
          loading in the future. Meanwhile, please try adding it with our Chrome
          extension.
        </p>
      </>
    ),
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));

const buttonLabels = {
  project: 'Create New Project',
  review: 'Add Web Pages',
  comment: 'Drop Comments',
  annotate: 'Annotate Screenshots',
};

const ChromeExtension = () => {
  const icons = {
    project: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor">
        <path d="M5 12h14M12 5v14" />
      </svg>
    ),
    review: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor">
        <path d="m16.02 12 5.48 3.13a1 1 0 0 1 0 1.74L13 21.74a2 2 0 0 1-2 0l-8.5-4.87a1 1 0 0 1 0-1.74L7.98 12" />
        <path d="M13 13.74a2 2 0 0 1-2 0L2.5 8.87a1 1 0 0 1 0-1.74L11 2.26a2 2 0 0 1 2 0l8.5 4.87a1 1 0 0 1 0 1.74Z" />
      </svg>
    ),
    comment: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor">
        <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" />
      </svg>
    ),
    annotate: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor">
        <path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
        <path d="M18.375 2.625a1 1 0 0 1 3 3l-9.013 9.014a2 2 0 0 1-.853.505l-2.873.84a.5.5 0 0 1-.62-.62l.84-2.873a2 2 0 0 1 .506-.852z" />
      </svg>
    ),
  };

  const images = {
    review: '/assets/img/extension/add-page.svg',
    comment: '/assets/img/extension/comment.svg',
    project: '/assets/img/extension/new-project.svg',
    annotate: '/assets/img/extension/annotate.svg',
  };

  const [currentIcon, setCurrentIcon] = useState('project');

  const handleIconChange = (iconKey) => {
    setCurrentIcon(iconKey);
  };

  return (
    <Layout hideHeader hideTopBar>
      <Meta
        title="Ruttl Suite of products 🌐 | ruttl features"
        description="Try ruttl’s suite of products for free"
        url="https://ruttl.com/features/"
      />
      <main>
        <Header hideTopBar hideHeader />
        <section className="hero-section-1 container">
          <div className="container1">
            <img
              className="bg-glow"
              loading="lazy"
              src="/assets/img/extension/bg-glow.svg"
              alt="Website extension UI"
              title="Website extension UI"
            />
            <img
              className="dots"
              loading="lazy"
              src="/assets/img/extension/dotted.svg"
              alt="Website extension UI"
              title="Website extension UI"
            />
            <span className="container-title">RUTTL EXTENSION</span>
            <h1 className="container-heading">
              Boost your feedback process with our
              <span> chrome extension</span>
            </h1>

            <a
              type="button"
              target="blank"
              href="https://chromewebstore.google.com/detail/ruttl/doecfodblgfnjmmphbedjgllipnbgbld">
              <span className="add">Add to Chrome,</span>
              <span> It&apos;s Free</span>
            </a>
            <img
              className="ruttl-overview"
              loading="lazy"
              src="/assets/img/extension/ruttl-overview.svg"
              alt="Website extension UI"
              title="Website extension UI"
            />
          </div>
        </section>

        <section className="get-started-section-1 container">
          <div>
            <span className="get-started-tag">GET STARTED</span>
            <h1 className="get-started-title">Start with 3 simple steps</h1>
          </div>
          <div className="steps">
            <div className="individual-step">
              <h4 className="step-number">Step 1</h4>
              <div className="individual-step-content">
                <img
                  className="step1-img"
                  loading="lazy"
                  src="/assets/img/extension/Step1.png"
                  alt="Website extension UI"
                  title="Website extension UI"
                />
              </div>
              <a
                type="button"
                target="blank"
                href="https://chromewebstore.google.com/detail/ruttl/doecfodblgfnjmmphbedjgllipnbgbld">
                <h4
                  id="individual-step-1-title"
                  className="individual-step-title">
                  Download our Extension
                </h4>
              </a>
            </div>
            <div className="individual-step">
              <h4 className="step-number">Step 2</h4>
              <div className="individual-step-content">
                <img
                  className="step2-img"
                  loading="lazy"
                  src="/assets/img/extension/step2.svg"
                  alt="Website extension UI"
                  title="Website extension UI"
                />
              </div>
              <h4 className="individual-step-title">Login with your account</h4>
            </div>
            <div className="individual-step">
              <h4 className="step-number">Step 3</h4>
              <div className="individual-step-content">
                <img
                  className="step3-img"
                  loading="lazy"
                  src="/assets/img/extension/step3.svg"
                  alt="Website extension UI"
                  title="Website extension UI"
                />
              </div>
              <h4 className="individual-step-title">
                Review apps & track bugs
              </h4>
            </div>
          </div>
        </section>

        <section className="review-section container ">
          <div>
            <span className="review-section-tag">REVIEW YOUR WEB APPS</span>
            <h1 className="review-section-title">
              <p>No more screenshots or</p>
              <p>clumsy excel sheets</p>
            </h1>
          </div>

          <div className="review-tabs">
            <div className="review-btn">
              {Object.keys(icons).map((key) => (
                <button
                  key={key}
                  type="button"
                  className={`individual-review-btn ${
                    currentIcon === key ? 'active' : ''
                  }`}
                  onClick={() => handleIconChange(key)}>
                  <div className="individual-review-btn-icon">{icons[key]}</div>
                  <span>{buttonLabels[key]}</span>
                </button>
              ))}
            </div>

            <div className="review-img-box">
              <div className="review-img">
                <img src={images[currentIcon]} alt={currentIcon} />
              </div>
            </div>
          </div>
        </section>
        <section className="functionalities-section container ">
          <div>
            <span className="functionalities-section-tag">
              REVIEW YOUR WEB APPS
            </span>
            <p className="functionalities-section-title">
              Build for teams to improve their workflow.
            </p>
          </div>

          <div className="functionality">
            <div className="individual-functionality">
              <div className="individual-functionality-title">
                <h4>DASHBOARD</h4>
                {/* <div>
                  <p>Access your project dashboard</p>
                  <p>in one click</p>
                </div> */}
                <p>Access your project dashboard in one click.</p>
              </div>
              <img
                src="/assets/img/extension/functionality1.png"
                alt=""
                className="individual-functionality-img"
              />
            </div>
            <div className="individual-functionality">
              <div className="individual-functionality-title">
                <h4>ASSIGN MEMBERS</h4>
                {/* <div>
                  <p>Assign team member within</p>
                  <p>the screenshot feedback</p>
                </div> */}
                <p>Assign team member within the screenshot feedback.</p>
              </div>
              <img
                src="/assets/img/extension/assign-members.png"
                alt=""
                className="individual-functionality-img"
              />
            </div>
            <div className="individual-functionality">
              <div className="individual-functionality-title">
                <h4>SET DUE DATES</h4>
                {/* <div>
                  <p>Set due dates ahead of time to</p>
                  <p>meet your deadline promptly</p>
                </div> */}
                <p>
                  Set due dates ahead of time to meet your deadline promptly.
                </p>
              </div>
              <img
                src="/assets/img/extension/due-dates.png"
                alt=""
                className="individual-functionality-img"
              />
            </div>
            <div className="individual-functionality">
              <div className="individual-functionality-title">
                <h4>PRIORITIZE FEEDBACK</h4>
                <p>Prioritize your feedback to enhance project efficiency.</p>
              </div>
              <img
                src="/assets/img/extension/feedback.png"
                alt=""
                className="individual-functionality-img"
              />
            </div>
          </div>
        </section>

        <TestimonialsSlider />

        <NewFaq data={faqData} />
      </main>
    </Layout>
  );
};

export default ChromeExtension;
